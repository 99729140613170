body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./background.jpg");
  background-repeat: repeat;
    background-size: 100%;
    background-position: center;
}

.navbar {
  background-image: url("./header.png");
  background-size: auto 100%;
  
  .navbar-brand {
    color: #fff;
  }
}

.card-footer {
  background-image: url("./stripes.png");
  background-size: auto 100%;
}

.btn-info {
  background-color: #A58900;
  color: #fff;
  text-shadow: 0 0 3px #000, 1px 1px 6px #000;

  &:hover {
    background-color: #C6A200;
  }
}

.card-title  {
  &.h1, &.h2, &.h3, &.h4, &.h5, &.h6 {
    color: #C6A200;
  }
} 

.text-info {
  color: #C6A200 !important; 
}

textarea {
  &.form-control {
    height: auto!important;
  }
}

.round-pill {
  border-radius: 10px;
  background-color: #C6A200;
  width: 20px;
  height: 20px;
  text-align: center;
  font-weight: bolder;
  color: #000;
}

.nav-tabs .nav-item {
  margin-right: 0;
}